<template>
  <div class="account-pages my-5 pt-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6 col-xl-5">
          <div class="card overflow-hidden">
            <div class="bg-primary">
              <div class="text-primary text-center p-4">
                <h5 class="text-white font-size-20">Welcome Back !</h5>
                <p class="text-white-50">Sign in to continue to Surface Digital.</p>
                <a href="/" class="logo logo-admin">
                  <img src="@/assets/images/logo.png" height="50" alt="logo" />
                </a>
              </div>
            </div>
            <div class="card-body p-4">
              <div class="p-3">
                <b-alert
                  v-model="isAuthError"
                  variant="danger"
                  class="mt-3"
                  dismissible
                >{{authError}}</b-alert>
                   <b-alert
                  v-model="IsSuccess"
                  variant="success"
                  class="mt-3"
                  dismissible
                >Login Success</b-alert>

                <b-form @submit.prevent="login" class="form-horizontal mt-4">
                  <b-form-group id="input-group-1" label="Email" label-for="input-1">
                    <b-form-input
                      id="input-1"
                      v-model="email"
                      type="email"
                      required
                      placeholder="Enter email"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    id="input-group-2"
                    label="Password"
                    label-for="input-2"
                    class="mb-3"
                  >
                    <b-form-input
                      id="input-2"
                      required
                      v-model="password"
                      type="password"
                      placeholder="Enter password"
                    ></b-form-input>
                  </b-form-group>

                  <div class="form-group row">
                    <div class="col-sm-6">
                      <b-form-checkbox
                        id="checkbox-1"
                        name="checkbox-1"
                        value="accepted"
                        unchecked-value="not_accepted"
                      >Remember me</b-form-checkbox>
                    </div>
                    <div class="col-sm-6 text-right">
                      <b-button type="submit" variant="primary" class="w-md">Log In</b-button>
                    </div>
                  </div>

                  <div class="form-group mt-2 mb-0 row">
                    <div class="col-12 mt-4">
                      <router-link tag="a" to="/forgot-password">
                        <i class="mdi mdi-lock"></i> Forgot your password?
                      </router-link>
                    </div>
                  </div>
                </b-form>
              </div>
            </div>
            <!-- end card-body -->
          </div>
          <!-- end card -->
        </div>
        <!-- end col -->
      </div>
      <!-- end row -->
    </div>
  </div>
</template>
<script>

import appConfig from "@/app.config";
import cookie from 'js-cookie';
/**
 * Login component
 */
export default {
 page: {
    title: "Login",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {},
  data() {
    return {
      email: "",
      password: "",
      authError: null,
      IsSuccess:false,
      tryingToLogIn: false,
      isAuthError: false
    };
  },
  methods: { 
    login(){
      let param = new FormData();
      param.append('email', this.email) 
      param.append('password', this.password) 
      this.$axios.post('/auth/login/staff', param)
      .then( res => { 
          let userdata = res.data.result;  
          this.$store.commit("SET_LOGIN", userdata);
          cookie.set("userdata", userdata, { expires: 1 }); 
          this.IsSuccess = true
          setTimeout(() => {
            window.location.reload()
          }, 500);
      }).catch(e => {
          if (e.response) {  
              this.isAuthError = true
              this.authError = e.response.data.message
         }
      })
    } 
  }
};
</script>

<style lang="scss" module></style>
